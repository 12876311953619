<template>
  <div class="w-100 d-flex p-relative">
    <div class="w-25">
      <img src="/assets/img/banner/65967.jpg" alt="" class="banner-item">
    </div>
    <div class="w-25">
      <img src="/assets/img/banner/01.jpg" alt="" class="banner-item">
    </div>
    <div class="w-25">
      <img src="/assets/img/banner/5620598.jpg" alt="" class="banner-item">
    </div>
    <div class="w-25">
      <img src="/assets/img/banner/590.jpg" alt="" class="banner-item">
    </div>
    <div class="df-c adver-banner-txt fs23 weight-700">
      {{ $t('TOURISM.ADVERTISING_TRADE_SHOWS') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TradeShowsBanner'    
}
</script>

<style lang="scss" scoped>
  .banner-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .adver-banner-txt {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, .5);
    padding: 5px 20px;
    color: #fff;
  }
</style>