<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <TradeShowsBanner></TradeShowsBanner>
    <section class="tourism-container">
      <p class="text-center weight-700 m-t-20">
        {{ $t('TOURISM.EXHIBITION_FOR_ADVERTISING_PROMOTIONAL_ITEMS') }}
      </p>
      <div class="d-flex">
        <SortTourism @sortDeals="sortDeals" :isTourismDeal="true"></SortTourism>
        <FilterTourism></FilterTourism>
      </div>
      <div class="row m-t-20">
        <div class="col-sm-3">
          <ExhibitionItems @openMoreDate="openTradeDetailModal" :item="test1"></ExhibitionItems>
        </div>
        <div class="col-sm-3">
          <ExhibitionItems @openMoreDate="openTradeDetailModal" :item="test2"></ExhibitionItems>
        </div>
        <div class="col-sm-3">
          <ExhibitionItems @openMoreDate="openTradeDetailModal" :item="test3"></ExhibitionItems>
        </div>
        <div class="col-sm-3">
          <ExhibitionItems @openMoreDate="openTradeDetailModal" :item="test1"></ExhibitionItems>
        </div>
        <div class="col-sm-3">
          <ExhibitionItems @openMoreDate="openTradeDetailModal" :item="test1"></ExhibitionItems>
        </div>
        <div class="col-sm-3">
          <ExhibitionItems @openMoreDate="openTradeDetailModal" :item="test1"></ExhibitionItems>
        </div>
        <div class="col-sm-3">
          <ExhibitionItems @openMoreDate="openTradeDetailModal" :item="test1"></ExhibitionItems>
        </div>
        <div class="col-sm-3">
          <ExhibitionItems @openMoreDate="openTradeDetailModal" :item="test1"></ExhibitionItems>
        </div>
      </div>
    </section>
    <TradeDetailModal></TradeDetailModal>
  </main>
</template>

<script>
import TradeShowsBanner from "../../../components/tourism/tradeShow/TradeShowsBanner.vue" 
import ExhibitionItems from "../../../components/tourism/tradeShow/ExhibitionItems.vue" 
import FilterTourism from "../../../components/tourism/FilterTourism";
import SortTourism from "../../../components/tourism/SortTourism";
import TradeDetailModal from "../../../components/tourism/tradeShow/TradeDetailModal.vue";

export default {
  name: 'TradeShowsCategory',
  components: {
    TradeShowsBanner,
    ExhibitionItems,
    FilterTourism,
    SortTourism,
    TradeDetailModal
  }, 
  data() {
    return {
      test1: {
        country: 'us',
        logo: '3.png'
      },
      test2: {
        country: 'fr',
        logo: '2.png'
      },
      test3: {
        country: 'gb',
        logo: '1.png'
      },
    }
  },
  methods: {
    sortDeals() {
    },
    openTradeDetailModal() {
      this.$modal.show("TradeDetailModal");
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";


</style>